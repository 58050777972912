import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/layout/Layout';
import '../css/custom/legal.css'
import Header from '../components/layout/Header';

const LegalDocuments = ({ data, location }) => {
  const { name, document } = data.contentfulLegal;
  let title = 'Legal Doc';
  let description = 'Legal Doc';
  if (name.toLowerCase().includes("privacy")) {
    title = "Privacy Policy | Kontor";
    description = "Privacy policy for Kontor.";
  }
  if (name.toLowerCase().includes("terms")) {
    title = "Terms and Conditions | Kontor";
    description = "Terms and conditons for Kontor.";
  }
  if (name.toLowerCase().includes("cookie")) {
    title = "Cookie Policy | Kontor";
    description = "Cookie Policy for Kontor.";
  }

  const options = {
    renderNode: {
      [BLOCKS.TABLE]: (node, children) => (
        <table>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
    },
  }
  console.log('documet', document.json);
  return (
    <Layout
      location={location}
    >
      <Header title={title} description={description} />
      <div className="legal mb-48 mt-2 mt-24 max-w-4xl mx-auto px-0">
        <h1 className="text-5xl text-center ">{name}</h1>
        {documentToReactComponents(document.json, options)}
      </div>

    </Layout>
  )
}

LegalDocuments.propTypes = {
  data: PropTypes.object.isRequired
}


export const pageQuery = graphql`
  query($id: String!) {
    contentfulLegal(id: { eq: $id }) {
        id
        slug
        name
        document {
          json
        }
    }
  }
`;

export default LegalDocuments
